import styles from '@/styles/superstack/layout/SSFooter.module.scss';
import classNames from 'classnames/bind';
import LinkTag from '@/src/component/LinkTag';
import IconGo from '@/asset/superstack/svgs/icon-go.svg';
import ImgLogo from '@/asset/superstack/svgs/logo-wishket-jobs_black.svg';
import IconCall from '@/asset/superstack/svgs/icon-call-black.svg';
import IconEmail from '@/asset/superstack/svgs/icon-email-black.svg';
import { GeneralLinks } from '@/data/superstack/GeneralLinks';
import { managerEmail, managerPhone } from '@/src/consts/superstack';

const cx = classNames.bind(styles);

const SSFooterMenuPC = () => {
  return (
    <div className={cx(['footer-menu-pc'])}>
      <div className={cx(['footer-menu-col'])}>
        <div className={cx(['footer-main-menu'])}>위시켓 잡스</div>
        <div className={cx(['footer-sub-menu'])}>
          <LinkTag
            className={cx(['link-tag'])}
            isNextPage={true}
            href={'/wishket-member/terms-of-service/'}
          >
            이용약관
          </LinkTag>
        </div>
        <div className={cx(['footer-sub-menu'])}>
          <LinkTag className={cx(['link-tag'])} isNextPage={true} href={'/wishket-member/privacy/'}>
            개인 정보 처리방침
          </LinkTag>
        </div>
      </div>
      <div className={cx(['footer-menu-col'])}>
        <div className={cx(['footer-main-menu'])}>이용 방법</div>
        <div className={cx(['footer-sub-menu'])}>
          <LinkTag
            className={cx(['link-tag'])}
            isNextPage={true}
            href={GeneralLinks().find((link) => link.name === '자주 묻는 질문')?.url}
            target={'_blank'}
          >
            자주 묻는 질문
          </LinkTag>
        </div>
      </div>
      <div className={cx(['footer-menu-col'])}>
        <div className={cx(['footer-main-menu'])}>
          <LinkTag
            className={cx(['link-tag'])}
            isNextPage={true}
            href={'/superstack/companies/main/'}
          >
            기업 서비스
            <IconGo />
          </LinkTag>
        </div>
      </div>
      <div className={cx(['footer-menu-col'])}>
        <div className={cx(['footer-main-menu'])}>
          <a
            className={cx(['link-tag'])}
            href={GeneralLinks().find((link) => link.name === '위시켓').url}
            style={{ textDecoration: 'none' }}
          >
            위시켓
            <IconGo />
          </a>
        </div>
        <div className={cx(['footer-main-menu'])}>
          <LinkTag className={cx(['link-tag'])} isNextPage={true} href={'/magazine/'}>
            요즘IT
            <IconGo />
          </LinkTag>
        </div>
        <div className={cx(['footer-main-menu'])}>
          <LinkTag className={cx(['link-tag'])} isNextPage={true} href={'/unity_bill/'}>
            통합빌링 서비스
            <IconGo />
          </LinkTag>
        </div>
      </div>
    </div>
  );
};
const SSFooterContactUsPc = () => {
  return (
    <div className={cx(['footer-contact-pc'])}>
      <div className={cx(['footer-main-menu'])}>CONTACT US</div>
      <div className={cx(['footer-sub-menu'])}>{managerPhone} (10:00-18:00, 공휴일 제외)</div>
      <div className={cx(['footer-sub-menu'])}>{managerEmail}</div>
    </div>
  );
};
const SSFooterCompanyInfoPc = () => {
  return (
    <div style={{ display: 'flex' }}>
      <div className={cx(['footer-logo-pc'])}>
        <ImgLogo />
      </div>
      <div>
        <div>
          주식회사 위시켓 (대표이사: 박우범) / 서울특별시 강남구 테헤란로 211 한국고등교육재단빌딩
          3층 (주)위시켓
          <br />
          사업자등록번호: 209-81-57303 / 통신판매업신고: 제2018-서울강남-02337 호 / 직업정보제공사업
          신고번호: J1200020180019
        </div>
        <div style={{ marginTop: '8px' }}>© 2013 Wishket Corp.</div>
      </div>
    </div>
  );
};
const SSFooterMobile = ({ sx }) => {
  return (
    <div className={cx(['footer-container-mobile'])} style={sx}>
      <div className={cx(['footer-logo-mobile'])}>
        <ImgLogo />
      </div>
      <div className={cx(['footer-content-top-mobile'])}>
        <div className={cx(['footer-contact-mobile'])}>
          <div className={cx(['footer-contact-info-mobile'])}>
            <div className={cx(['footer-contact-text-mobile'])}>
              <div className={cx(['icon'])}>
                <IconCall />
              </div>
              <div>{managerPhone}</div>
            </div>
            <div className={cx(['footer-contact-text-mobile'])}>
              <div className={cx(['icon'])}>
                <IconEmail fill={'red'} />
              </div>
              <div>{managerEmail}</div>
            </div>
          </div>
        </div>
        <div className={cx(['footer-text'])} style={{ color: '#616161' }}>
          고객센터 운영시간 10:00 ~ 18:00 (공휴일 제외)
        </div>
        <div className={cx(['footer-text'])}>
          주식회사 위시켓 (대표이사: 박우범) / 서울특별시 강남구 테헤란로 211 한국고등교육재단빌딩
          3층 (주)위시켓
        </div>
        <div className={cx(['footer-text', 'last-footer-text'])}>
          사업자등록번호: 209-81-57303 / 통신판매업신고: 제2018-서울강남-02337 호 / 직업정보제공사업
          신고번호: J1200020180019
        </div>
      </div>
      <div className={cx(['footer-text', 'footer-copyright-mobile'])}>© 2013 Wishket Corp.</div>
    </div>
  );
};
export default function SSFooter({ sx }) {
  return (
    <footer>
      <div className={cx(['footer-container-pc'])}>
        <div className={cx(['footer-content-top-pc'])}>
          <SSFooterMenuPC />
          <SSFooterContactUsPc />
        </div>
        <div className={cx(['footer-content-bottom-pc'])}>
          <SSFooterCompanyInfoPc />
        </div>
      </div>
      <SSFooterMobile sx={sx} />
    </footer>
  );
}
