import TagManager from 'react-gtm-module';

const handlePushUserId = (user) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        userId: user.username,
      },
    });
  }
};

const handleClickApplyEvt = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onClickApply',
      },
    });
  }
};

const handleClickSignUpEvt = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onClickSignUp',
      },
    });
  }
};

const handlePageViewOfMember = (profileType) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageViewOfMember', // GTM 트리거
        jobs_profile_type: profileType,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view_of_member', // GTM 이벤트
        jobs_profile_type: profileType,
      },
    });
  }
};

const handleSubmitContactInfoEvt = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onSubmitContactInfo',
      },
    });
  }
};

const handleChangeJobSortEvt = (new_sort) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onChangeJobSort',
        job_sort_type: new_sort,
      },
    });
  }
};

const handleChangeCultureSortEvt = (new_sort) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onChangeCultureSort',
        culture_sort_type: new_sort,
      },
    });
  }
};

const handleAgreeMatchingEvt = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onAgreeMatching',
      },
    });
  }
};

const handleWritingDefaultProfileAgainEvt = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onWritingDefaultProfileAgain',
      },
    });
  }
};

const handleCloseDefaultProfileEvt = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onCloseDefaultProfile',
      },
    });
  }
};

const handleSubmitDefaultProfileEvt = (menu) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onSubmitDefaultProfile',
        default_profile_menu: menu,
      },
    });
  }
};

const handleSubmitWelcomeDataEvt = (type) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onSubmitWelcomeData',
        test_data_type: type,
      },
    });
  }
};

const handleCloseWelcomeDataEvt = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onCloseWelcomeData',
      },
    });
  }
};

const handleClickCompanySubscriptionEvt = () => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onClickCompanySubscription',
      },
    });
  }
};

export {
  handlePushUserId,
  handleClickApplyEvt,
  handleClickSignUpEvt,
  handlePageViewOfMember,
  handleSubmitContactInfoEvt,
  handleChangeJobSortEvt,
  handleChangeCultureSortEvt,
  handleAgreeMatchingEvt,
  handleWritingDefaultProfileAgainEvt,
  handleCloseDefaultProfileEvt,
  handleSubmitDefaultProfileEvt,
  handleSubmitWelcomeDataEvt,
  handleCloseWelcomeDataEvt,
  handleClickCompanySubscriptionEvt,
};
