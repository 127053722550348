import Image from 'next/image';

const myLoader = ({ src, width, quality }) => {
  return `${src}?w=${width}&q=${quality}`;
};

const SSImage = ({
  src = '',
  alt = 'Pic',
  layout = 'intrinsic',
  objectFit = 'cover',
  priority = false,
  quality = 75,
  ...props
}) => {
  if (!src || (typeof src === 'object' && !src.src)) {
    return null;
  }
  return (
    <Image
      loader={myLoader}
      src={src}
      alt={alt}
      layout={layout}
      objectFit={objectFit}
      priority={priority}
      quality={quality}
      {...props}
    />
  );
};

export default SSImage;
