import useSWR from 'swr';
import { api } from '@/src/utils/api';
import { ApiUrls } from '@/data/superstack/ApiUrls';

export default function useSSProfile() {
  const fetcher = async (...args) =>
    api.get(...args).then((res) => {
      switch (res.status) {
        case 200:
          return res;
        case 404:
          throw new Error('No Users Found');
        default:
          return res;
      }
    });
  const { data, error, mutate } = useSWR(
    ApiUrls().find((url) => url.name === 'ss_user_info').url,
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnMount: true,
    },
  );
  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
