import { SSActivationModal } from '@/src/layout/superstack/modals/SSActivationModal';
import { SSEmailAuthRequiredModal } from '@/src/layout/superstack/modals/SSEmailAuthRequiredModal';
import { useEffect, useState } from 'react';
import { ApiUrls } from '@/data/superstack/ApiUrls';
import { api, formDataApi } from '@/src/utils/api';
import { handleServerError } from '@/src/utils/ss_utils';
import { SSDefaultProfileModal } from '@/src/layout/superstack/modals/SSDefaultProfileModal';
import { getRejectSessionKey } from '@/src/services/superstack/profiles/cookies';

const handleActivation = async (username, setIsOpenActivationModal) => {
  const url = ApiUrls(null, null, username).find((url) => url.name === 'ss_activation').url;
  await api.patch(url);
  await setIsOpenActivationModal(false);
};

const handleResendEmailAuth = async (email) => {
  const data = new FormData();
  data.append('email', email);
  try {
    await formDataApi.post(ApiUrls().find((url) => url.name === 'resend_email').url, data);
    alert('이메일 전송이 완료되었습니다.');
  } catch (error) {
    handleServerError(error);
  }
};

const SSModalHandler = ({
  user,
  withActivationModal,
  withEmailAuthRequiredModal,
  withDefaultProfileModal,
}) => {
  const [isOpenActivationModal, setIsOpenActivationModal] = useState(false);
  const [isOpenEmailAuthRequiredModal, setIsOpenEmailAuthRequiredModal] = useState(false);
  const [isOpenDefaultProfileModal, setIsOpenDefaultProfileModal] = useState(false);

  const wSessionId = user.wsessionid;
  const rejectSessionKey = getRejectSessionKey();
  const rejectingDefaultProfile = wSessionId && rejectSessionKey && wSessionId === rejectSessionKey;

  useEffect(() => {
    setIsOpenActivationModal(!!user.need_ss_activation);
    setIsOpenEmailAuthRequiredModal(!!user.need_email_auth);
    setIsOpenDefaultProfileModal(!!user.need_default_profile);
  }, [user.need_ss_activation, user.need_email_auth, user.need_default_profile]);

  return (
    <>
      {withActivationModal && (
        <SSActivationModal
          isOpen={isOpenActivationModal}
          onSubmit={() => {
            handleActivation(user.username, setIsOpenActivationModal);
          }}
        />
      )}
      {withEmailAuthRequiredModal && !isOpenActivationModal && (
        <SSEmailAuthRequiredModal
          isOpen={isOpenEmailAuthRequiredModal}
          onSubmit={handleResendEmailAuth}
        />
      )}
      {withDefaultProfileModal && !rejectingDefaultProfile && (
        <SSDefaultProfileModal
          isOpen={isOpenDefaultProfileModal}
          setIsOpen={setIsOpenDefaultProfileModal}
        />
      )}
    </>
  );
};

export { SSModalHandler };
