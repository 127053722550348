import classNames from 'classnames/bind';
import styles from '@/styles/superstack/layout/modals/SSAfterSignupModal.module.scss';
import ReactModal from 'react-modal';
import { Button } from '@/src/component/superstack/base/Button';
import { useRecoilValue } from 'recoil';
import { mobileState } from '@/src/utils/hooks/useMobileSize';
import { handleServerError } from '@/src/utils/ss_utils';
import { formDataApi } from '@/src/utils/api';
import useSSProfile from '@/src/utils/hooks/superstack/useSSProfile';
import { ApiUrls } from '@/data/superstack/ApiUrls';

const SSEmailAuthRequiredModal = ({ isOpen }) => {
  const { user: { data: user = {} } = {} } = useSSProfile();
  const cx = classNames.bind(styles);
  const isMobile = useRecoilValue(mobileState);

  const handleSubmit = async () => {
    const data = new FormData();
    data.append('email', user.email);
    try {
      await formDataApi.post(ApiUrls().find((url) => url.name === 'resend_email').url, data);
      alert('이메일 전송이 완료되었습니다.');
    } catch (error) {
      handleServerError(error);
    }
  };

  const style = isMobile
    ? {
        overlay: {},
        content: {
          'box-shadow': '0px 10px 37px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          margin: 'auto',
          padding: 0,
          position: 'static',
          width: '100%',
          height: '100%',
          zIndex: 10,
        },
      }
    : {
        overlay: {},
        content: {
          boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          margin: 'auto',
          padding: 0,
          position: 'relative',
          width: '362px',
          maxHeight: '90%',
        },
      };

  return (
    <ReactModal isOpen={isOpen} style={style} preventScroll={true}>
      <div className={cx('ss-after-signup-modal')}>
        <div className={cx('modal-head')}>
          <div className={cx('content-wrapper')}>이메일 인증</div>
        </div>
        <div className={cx('modal-body')} style={{ padding: '30px 20px' }}>
          <section className={cx('top')}>
            <div className={cx('title')}>
              {user.username}님, <br />
              이메일을 인증해 주세요!
            </div>
            <div className={cx('subtitle')}>
              <span className={cx('email')}>{user.email}</span>로 인증 메일이 발송되었습니다.
              <br />
              이메일 인증 후 위시켓 잡스 서비스 이용이 가능합니다.
            </div>
          </section>
          <section className={cx('mid')}>
            <div className={cx('info-box')}>
              <p>인증 메일을 받지 못하셨나요?</p>
              <p>7일 이내에 이메일의 링크를 클릭해 주시면 인증이 완료됩니다.</p>
              <p>메일을 받지 못했다면 스팸함을 확인해 주세요.</p>
            </div>
          </section>
          <section className={cx('bottom')}>
            <div className={cx('btn-wrapper')}>
              <Button size={'xl'} mode={'filled'} onClick={handleSubmit}>
                인증 메일 다시 받기
              </Button>
            </div>
          </section>
        </div>
      </div>
    </ReactModal>
  );
};

export { SSEmailAuthRequiredModal };
