import Head from 'next/head';
import { SSHeader } from '@/src/layout/superstack/SSHeader';
import SSFooter from '@/src/layout/superstack/SSFooter';
import { useEffect } from 'react';
import useSSProfile from '@/src/utils/hooks/superstack/useSSProfile';
import ReactModal from 'react-modal';
import { handlePageViewOfMember, handlePushUserId } from '@/src/utils/tracking_analytics';

import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga4';

import { SSModalHandler } from '@/src/layout/superstack/modals/SSModalHandler';

const SSLayout = ({
  pageTitle,
  referer,
  width = '100%',
  minHeight = '80vh',
  sx = {},
  Header = SSHeader,
  withHeader = true,
  withFooter = true,
  withActivationModal = true,
  withEmailAuthRequiredModal = true,
  withDefaultProfileModal = true,
  ...props
}) => {
  const { user: { data: user = {} } = {}, isLoading } = useSSProfile();

  useEffect(() => {
    ReactModal.setAppElement('main');
    ReactModal.defaultStyles = {
      content: { ...ReactModal.defaultStyles.content },
      overlay: { ...ReactModal.defaultStyles.overlay, zIndex: 9999 },
    };

    if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
      hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_ID, 1);
    }
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      });
    }
  }, []);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GA4_ID && !isLoading) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA4_ID);
      ReactGA.set({
        user_properties: {
          w_is_staff: user.w_is_staff,
          w_user_usage: user.w_user_usage,
          w_is_authenticated: user.w_is_authenticated,
        },
      });
    }
  }, [isLoading]);

  useEffect(() => {
    handlePushUserId(user);
  }, [user.username]);

  useEffect(() => {
    if (user?.is_staff !== undefined) {
      handlePageViewOfMember(user?.is_staff ? 'manager' : 'user');
    }
  }, [user?.is_staff]);

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/static/superstack_favicon.ico" />
        <title>{pageTitle}</title>
        <meta name="facebook-domain-verification" content="pixlvjn7edj6w17vy1zjj78njyoeii" />
      </Head>
      {withHeader && <Header pageTitle={pageTitle} referer={referer} user={user} />}
      <main style={{ maxWidth: width, minHeight: minHeight, margin: '0 auto', ...sx }}>
        {props.children}
      </main>

      <SSModalHandler
        user={user}
        withActivationModal={withActivationModal}
        withEmailAuthRequiredModal={withEmailAuthRequiredModal}
        withDefaultProfileModal={withDefaultProfileModal}
      />
      {withFooter && <SSFooter />}
    </>
  );
};

const MSSLayout = ({
  pageTitle,
  referer,
  minHeight = '80vh',
  Header = SSHeader,
  withHeader = true,
  withFooter = true,
  sxFooter = {},
  withActivationModal = true,
  withEmailAuthRequiredModal = true,
  withDefaultProfileModal = true,
  ...props
}) => {
  const { user: { data: user = {} } = {}, isLoading } = useSSProfile();

  useEffect(() => {
    ReactModal.setAppElement('main');
    ReactModal.defaultStyles = {
      content: { ...ReactModal.defaultStyles.content },
      overlay: { ...ReactModal.defaultStyles.overlay, zIndex: 9999 },
    };

    if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
      hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_ID, 1);
    }
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      });
    }
  }, []);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GA4_ID && !isLoading) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA4_ID);
      ReactGA.set({
        user_properties: {
          w_is_staff: user.w_is_staff,
          w_user_usage: user.w_user_usage,
          w_is_authenticated: user.w_is_authenticated,
        },
      });
    }
  }, [isLoading]);

  useEffect(() => {
    handlePushUserId(user);
  }, [user.username]);

  useEffect(() => {
    if (user?.is_staff !== undefined) {
      handlePageViewOfMember(user?.is_staff ? 'manager' : 'user');
    }
  }, [user?.is_staff]);

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/static/superstack_favicon.ico" />
        <title>{pageTitle}</title>
        <meta name="facebook-domain-verification" content="pixlvjn7edj6w17vy1zjj78njyoeii" />
      </Head>
      {withHeader && <Header pageTitle={pageTitle} referer={referer} user={user} />}
      <main style={{ minHeight: minHeight }}>{props.children}</main>
      <SSModalHandler
        user={user}
        withActivationModal={withActivationModal}
        withEmailAuthRequiredModal={withEmailAuthRequiredModal}
        withDefaultProfileModal={withDefaultProfileModal}
      />
      {withFooter && <SSFooter sx={sxFooter} />}
    </>
  );
};

const SSCompanyLayout = ({ ...props }) => {
  return (
    <SSLayout width={'1080px'} withDefaultProfileModal={false} {...props}>
      {props.children}
    </SSLayout>
  );
};
export { SSLayout, MSSLayout, SSCompanyLayout };
