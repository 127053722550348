export const CompanyGeneralLinks = (companyId) => {
  return [
    { id: 0, name: 'company_main_router', displayName: '메인', url: '/superstack/companies/main/' },
    {
      id: 1,
      name: 'create_new_company',
      displayName: '기업회원 시작하기',
      url: '/superstack/companies/new/',
    },
    {
      id: 2,
      name: 'job_register_new_job',
      displayName: '공고 등록',
      url: `/superstack/companies/${companyId}/register/new/`,
    },
    {
      id: 3,
      name: 'company_jobs',
      displayName: '공고 관리',
      url: `/superstack/companies/${companyId}/jobs/`,
    },
    {
      id: 4,
      name: 'company_manage_router',
      displayName: '회사 정보',
      url: `/superstack/companies/${companyId}/manage/`,
    },
    {
      id: 5,
      name: 'company_admins',
      displayName: '관리자 설정',
      url: `/superstack/companies/${companyId}/admins/`,
    },
    {
      id: 6,
      name: 'companies_stacks',
      displayName: '기술 스택',
      url: `/superstack/companies/${companyId}/stacks/`,
    },
    {
      id: 7,
      name: 'company_landing',
      displayName: '기업 랜딩',
      url: '/superstack/companies/landing/',
    },
    {
      id: 9,
      name: 'company_home',
      displayName: '기업홈',
      url: `/superstack/companies/${companyId}/home/`,
    },
    {
      id: 11,
      name: 'company_stacks_router',
      displayName: '기업 기술 스택폼 라우터',
      url: '/superstack/companies/stacks-form/router/',
    },
    {
      id: 12,
      name: 'company_manage_stacks_form',
      displayName: '기업 기술 스택폼',
      url: `/superstack/companies/${companyId}/manage/stacks-form/`,
    },
    {
      id: 13,
      name: 'company_service_form_router',
      displayName: '프로덕트폼 라우터',
      url: `/superstack/companies/${companyId}/manage/service-form/router/`,
    },
    {
      id: 14,
      name: 'company_service_form',
      displayName: '프로덕트폼',
      url: `/superstack/companies/${companyId}/manage/service-form/`,
    },
  ];
};
