import classNames from 'classnames/bind';
import styles from '@/styles/superstack/layout/modals/SSActivationModal.module.scss';
import ReactModal from 'react-modal';
import { ButtonBlock } from '@/src/component/superstack/base/Button';
import ImgWishketMembers from '@/asset/superstack/svgs/img-wishket-members.svg';
import LinkTag from '@/src/component/LinkTag';
import { useRecoilValue } from 'recoil';
import { mobileState } from '@/src/utils/hooks/useMobileSize';

const SSActivationModal = ({ isOpen, onSubmit }) => {
  const cx = classNames.bind(styles);
  const isMobile = useRecoilValue(mobileState);

  const style = isMobile
    ? {
        overlay: {},
        content: {
          'box-shadow': '0px 10px 37px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          margin: 'auto',
          padding: 0,
          position: 'static',
          width: '100%',
          height: '100%',
          zIndex: 10,
        },
      }
    : {
        overlay: {},
        content: {
          boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          margin: 'auto',
          padding: 0,
          position: 'relative',
          width: '520px',
          maxHeight: '90%',
        },
      };

  return (
    <ReactModal isOpen={isOpen} style={style} preventScroll={true}>
      <div className={cx('ss-activation-modal')}>
        <div className={cx('modal-only-body')}>
          <section className={cx('top')}>
            <div className={cx('content-wrapper')}>
              <div className={cx('title')}>
                개발자 전용 구인구직 플랫폼 <br />
                위시켓 잡스에 오신것을 환영합니다.
              </div>
              <div className={cx('sub-title')}>
                위시켓 계정으로 편리하게 위시켓 잡스를 이용해 보세요 😃
              </div>
            </div>
          </section>
          <section className={cx('mid')}>
            <ImgWishketMembers />
          </section>
          <section className={cx('bottom')}>
            <span className={cx('label-text')}>
              <LinkTag
                href={'/wishket-member/terms-of-service/'}
                target={'_blank'}
                className={cx('link')}
              >
                위시켓 잡스 이용약관
              </LinkTag>{' '}
              및{' '}
              <LinkTag href={'/wishket-member/privacy/'} target={'_blank'} className={cx('link')}>
                개인정보 처리방침
              </LinkTag>
              에 동의합니다.
            </span>
          </section>
          <ButtonBlock
            size="lg"
            mode="filled"
            onClick={() => {
              onSubmit();
            }}
          >
            위시켓 잡스 이용약관 및 개인정보 처리방침 동의하고 시작하기
          </ButtonBlock>
        </div>
      </div>
    </ReactModal>
  );
};

export { SSActivationModal };
