import { useRouter } from 'next/router';

import classNames from 'classnames/bind';
import styles from '@/styles/superstack/layout/modals/SSDefaultProfileModal.module.scss';
import ReactModal from 'react-modal';
import { ButtonBlock } from '@/src/component/superstack/base/Button';
import { useRecoilValue } from 'recoil';
import { mobileState } from '@/src/utils/hooks/useMobileSize';
import ImgStackMatchModal from '@/asset/superstack/svgs/img-stack-match-modal.svg';
import ImgProfileMatchModal from '@/asset/superstack/svgs/img-profile-match-modal.svg';
import ImgArrowGrowth from '@/asset/superstack/pngs/img-arrow-growth.png';
import { ProfileManageLinks } from '@/data/superstack/ProfileManageLinks';
import { ApiUrls } from '@/data/superstack/ApiUrls';
import { api } from '@/src/utils/api';
import useSSProfile from '@/src/utils/hooks/superstack/useSSProfile';
import IconX from '@/asset/superstack/svgs/icon-x.svg';
import { setRejectDefaultProfileCookie } from '@/src/services/superstack/profiles/cookies';
import SSImage from '@/src/component/superstack/base/SSImage';
import {
  handleAgreeMatchingEvt,
  handleCloseDefaultProfileEvt,
  handleWritingDefaultProfileAgainEvt,
} from '@/src/utils/tracking_analytics';

const SSDefaultProfileModal = ({ isOpen, setIsOpen }) => {
  const { user: { data: user = {} } = {}, isLoading } = useSSProfile();
  const router = useRouter();
  const cx = classNames.bind(styles);
  const isMobile = useRecoilValue(mobileState);

  const handleAgree = async () => {
    !user.is_matching_agree ? handleAgreeMatchingEvt() : handleWritingDefaultProfileAgainEvt();
    if (!user.is_matching_agree) {
      await putMatchingAgree();
    }
    const url = ProfileManageLinks().find((link) => link.name === 'ss_profile_default_form')?.url;
    await router.push(url, url);
  };

  const putMatchingAgree = async () => {
    return api.put(
      ApiUrls(null, null, user.username).find((url) => url.name === 'ss_profile_matching_agree')
        ?.url,
    );
  };

  const handleClose = () => {
    handleCloseDefaultProfileEvt();
    setRejectDefaultProfileCookie(user.wsessionid);
    setIsOpen(false);
  };

  const style = isMobile
    ? {
        overlay: {
          backgroundColor: '#00000066',
        },
        content: {
          'box-shadow': '0px 10px 37px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          margin: 'auto',
          padding: 0,
          position: 'static',
          width: '100%',
          height: '100%',
          zIndex: 10,
        },
      }
    : {
        overlay: {
          backgroundColor: '#00000066',
        },
        content: {
          boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          margin: 'auto',
          padding: 0,
          position: 'relative',
          width: '320px',
          maxHeight: '90%',
          top: '140px',
        },
      };
  return (
    <ReactModal isOpen={isOpen} style={style} preventScroll={true}>
      <div className={cx('ss-default-profile-modal')}>
        <div className={cx('modal-only-body')} style={{ padding: '20px' }}>
          {user.first_signup_service !== 'superstack' && (
            <section className={cx('top')}>
              <span onClick={handleClose}>
                <IconX width={16} height={16} />
              </span>
            </section>
          )}
          <section className={cx('mid')}>
            {user.is_matching_agree ? (
              <SSImage width={200} height={120} src={ImgArrowGrowth} quality={100} />
            ) : (
              <ImgStackMatchModal width={200} height={160} />
            )}
          </section>
          <section className={cx('bottom')}>
            <div className={cx('content-wrapper')}>
              <div className={cx('sub-title')}>번거롭게 아직도 공고 찾아보고 있나요?</div>
              {user.is_matching_agree && (
                <div className={cx(['title', 'verbose'])}>
                  1분 만에 필수 프로필 작성하고
                  <br /> 연봉 점프업 기회를 잡으세요!
                </div>
              )}
              {!user.is_matching_agree && (
                <div className={cx('title')}>이제 내게 꼭 맞는 포지션만 제안 받아보세요.</div>
              )}
            </div>
          </section>
          {!isLoading && (
            <ButtonBlock size={'xl'} mode="filled" onClick={handleAgree}>
              {user.is_matching_agree ? '필수 프로필 작성하기' : '제안 받을래요'}
            </ButtonBlock>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

const SSDefaultProfileCompleteModal = ({ isOpen }) => {
  // 현재 미사용
  const router = useRouter();
  const cx = classNames.bind(styles);
  const isMobile = useRecoilValue(mobileState);

  const style = isMobile
    ? {
        overlay: {
          backgroundColor: '#00000066',
        },
        content: {
          'box-shadow': '0px 10px 37px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          margin: 'auto',
          padding: 0,
          position: 'static',
          width: '100%',
          height: '100%',
          zIndex: 10,
        },
      }
    : {
        overlay: {
          backgroundColor: '#00000066',
        },
        content: {
          boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          margin: 'auto',
          padding: 0,
          position: 'relative',
          width: '539px',
          maxHeight: '90%',
        },
      };

  return (
    <ReactModal isOpen={isOpen} style={style} preventScroll={true}>
      <div className={cx('ss-default-profile-modal')}>
        <div className={cx('modal-only-body')}>
          <section className={cx('mid')}>
            <ImgProfileMatchModal width={228} height={156} />
          </section>
          <section className={cx('bottom')}>
            <div className={cx('content-wrapper')}>
              <div className={cx('title-only')}>
                프로필 정보 추가하고 <br />더 Fit하게 포지션 제안 받아보세요.
              </div>
            </div>
          </section>
          <ButtonBlock
            size={'xl'}
            mode="filled"
            onClick={() => {
              const queryParams = { ...router.query };
              delete queryParams.modal;
              router.replace({
                pathname: router.pathname,
                query: queryParams,
              });
            }}
          >
            네, 좋아요!
          </ButtonBlock>
        </div>
      </div>
    </ReactModal>
  );
};

export { SSDefaultProfileModal, SSDefaultProfileCompleteModal };
