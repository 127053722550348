import { useEffect, useRef } from 'react';

const useOutsideClick = (callback) => {
  const ref = useRef();
  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    document.addEventListener('click', handleClick, { capture: true });
    return () => {
      document.addEventListener('click', handleClick, { capture: true });
    };
  }, [ref]);
  return ref;
};

export { useOutsideClick };
