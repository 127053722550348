import useSWRImmutable from 'swr/immutable';
import { api } from '@/src/utils/api';
import { ApiUrls } from '@/data/superstack/ApiUrls';

export default function useUserCompany(username) {
  const fetcher = async (...args) =>
    api.get(...args).then((res) => {
      switch (res.status) {
        case 200:
          return res;
        case 404:
          return {};
        default:
          return res;
      }
    });
  const { data, error, mutate } = useSWRImmutable(
    ApiUrls(null, null, username).find((url) => url.name === 'user_company').url,
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnMount: true,
    },
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
