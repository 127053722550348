export const managerEmail = 'jobs@wishket.com';
export const managerPhone = '02-6925-5965';

export const jobImageNames = [
  '사무실 사진',
  '개인좌석 사진',
  '회의실 사진',
  '화장실 사진',
  '부대시설 사진',
  '팀원 사진',
];

export const landingStacks = [
  ['Javascript', 'javascript'],
  ['TypeScript', 'typescript'],
  ['Vue.js', 'vue.js'],
  ['React', 'react'],
  ['Java', 'java'],
  ['Python', 'python'],
  ['Spring', 'spring'],
  ['AWS', 'aws'],
  ['MySQL', 'mysql'],
  ['Node.js', 'node.js'],
  ['React Native', 'react_native'],
  ['Jenkins', 'jenkins'],
  ['GraphQL', 'graphql'],
];
