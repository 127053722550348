import { setCookie, deleteCookie, getCookie } from 'cookies-next';

const setUpdatedProfileSkillsCookie = (maxAge = 5) => {
  setCookie(
    'successUpdateProfileSkillsMessage',
    '보유기술이 저장되었습니다!\n' +
      '간편하게 프로필 정보를 작성하고 나에게 가장 적합한 포지션을 만나보세요!',
    {
      maxAge: maxAge,
    },
  );
};

const setUpdatedProfileEmploymentCookie = (maxAge = 5) => {
  setCookie(
    'successUpdateProfileEmploymentMessage',
    '경력이 저장되었습니다!\n' +
      '간편하게 프로필 정보를 작성하고 나에게 가장 적합한 포지션을 만나보세요!',
    {
      maxAge: maxAge,
    },
  );
};

const setUpdatedProfileIntroductionCookie = (maxAge = 5) => {
  setCookie(
    'successUpdateProfileIntroductionMessage',
    '자기소개가 저장되었습니다!\n' +
      '간편하게 프로필 정보를 작성하고 나에게 가장 적합한 포지션을 만나보세요!',
    {
      maxAge: maxAge,
    },
  );
};

const getRejectSessionKey = () => {
  return getCookie('rejectDefaultProfileSessionKey');
};

const setRejectDefaultProfileCookie = (sessionId) => {
  deleteCookie('rejectDefaultProfileSessionKey');
  setCookie('rejectDefaultProfileSessionKey', sessionId);
};

export {
  setUpdatedProfileSkillsCookie,
  setUpdatedProfileEmploymentCookie,
  setUpdatedProfileIntroductionCookie,
  setRejectDefaultProfileCookie,
  getRejectSessionKey,
};
