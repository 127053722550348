import renewalStyles from '@/styles/superstack/layout/SSheader-renewal.module.scss';
import classNames from 'classnames/bind';
import LinkTag from '@/src/component/LinkTag';
import { GeneralLinks } from '@/data/superstack/GeneralLinks';
import { useRouter } from 'next/router';
const renewalCx = classNames.bind(renewalStyles);

const SSHeaderSNB = ({ isLogin = false }) => {
  const router = useRouter();
  return (
    <section className={renewalCx(['ssheader-snb', 'mobile'])}>
      <div className={renewalCx('container')}>
        <LinkTag
          isNextPage={true}
          href={GeneralLinks().find((link) => link.name === 'displaying_list')?.url}
          className={renewalCx([
            'sub-navigation',
            router.pathname === '/superstack/jobs/displaying' ||
            router.pathname === '/superstack/jobs/[id]'
              ? 'active'
              : '',
          ])}
        >
          <div>포지션 찾기</div>
        </LinkTag>
        <LinkTag
          isNextPage={true}
          href={GeneralLinks().find((link) => link.name === 'stacks_companies')?.url}
          className={renewalCx([
            'sub-navigation',
            router.pathname && router.pathname.includes('/superstack/stacks/companies')
              ? 'active'
              : '',
          ])}
        >
          <div>기술 스택</div>
        </LinkTag>
        <LinkTag
          isNextPage={true}
          href={GeneralLinks().find((link) => link.name === 'company_culture_review_results')?.url}
          className={renewalCx([
            'sub-navigation',
            router.pathname && router.pathname.includes('/superstack/stacks/cultures')
              ? 'active'
              : '',
          ])}
        >
          <div>컬처코드</div>
        </LinkTag>
        <LinkTag
          isNextPage={true}
          href={GeneralLinks().find((link) => link.name === 'ss_profile_mine_home')?.url}
          className={renewalCx([
            'sub-navigation',
            router.pathname && router.pathname.includes('/superstack/profiles/mine/')
              ? 'active'
              : '',
          ])}
        >
          <div>프로필 관리</div>
        </LinkTag>
        {isLogin && (
          <LinkTag
            isNextPage={true}
            href={GeneralLinks().find((link) => link.name === 'jobs_mine')?.url}
            className={renewalCx([
              'sub-navigation',
              router.pathname && router.pathname.includes('/superstack/apply/jobs/mine/')
                ? 'active'
                : '',
            ])}
          >
            <div>지원한 포지션</div>
          </LinkTag>
        )}
      </div>
    </section>
  );
};

export { SSHeaderSNB };
